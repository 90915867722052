import React, { useContext, useEffect, useState } from "react";
import { TournamentContext } from "./contexts/TournamentContext";
import { selectTeamRanking } from "../state/action-creators/action-creators";
import { ActionCreators } from "../state/action-creators";
import successViGroups from "./images/successViGroups.png";
import failureViGroups from "./images/failureViGroups.png";
import TournamentPhase from '../enums/tournamentPhase';

const dataSources = {
  user: "user",
  server: "server",
};

const selectedBtnColor = {
  backgroundColor: "#4C658A",
  boxShadow: "inset 0px 3px 4px rgba(0, 0, 0, 0.25)",
  color: "#FFFFFF",
};

const selectedBtnColorDisabled = {
  backgroundColor: "#CFCFCF",
  color: "#FFFFFF",
};

function GroupStageSingleTeamBox({
  teamName,
  flagImage,
  teamId,
  groupLetter,
  // selectedRanking,
  ranking,
}) {
  const { postOrPut, state, enableEditing, dispatch } =
    useContext(TournamentContext);

  var fullStringGroupName = groupLetter;
  var parts = fullStringGroupName?.split(" ");
  parts?.shift(); // parts is modified to remove first word
  let onlyLetterOfGroup = parts?.join(" ");

  let displayedBtn;
  if (postOrPut === "put") {
    if (enableEditing) {
      displayedBtn = selectedBtnColor;
    }

    if (!enableEditing) {
      displayedBtn = selectedBtnColorDisabled;
    }
  } else {
    displayedBtn = selectedBtnColor;
  }

  const groups = state?.tournament?.groups;
  const currentGroup = groups?.find(
    (group) => group?.displayName === groupLetter
  );

  const teams = currentGroup?.teams;
  const currentTeam = teams?.find((team) => team?.id === teamId);
  
  let viOrX, successOrFailure;
  
  if (state?.tournament?.tournamentPhase > TournamentPhase.beforeStart) {
    successOrFailure = (currentTeam?.selectedRanking ?? 5) === ranking;
    if (successOrFailure) {
      viOrX = successViGroups;
    } else {
      viOrX = failureViGroups;
    }
  }


  // const currentTeam = currentGroup?.find((group) => group?.teams)
  const handleClick = (rank, dataSource) => {
    if (enableEditing) {
      if (currentTeam?.selectedRanking === null) {
        dispatch(
          ActionCreators.selectTeamRanking(onlyLetterOfGroup, rank, teamId)
        );
      }
      if (currentTeam?.selectedRanking !== rank) {
        dispatch(ActionCreators.deselectTeamRanking(onlyLetterOfGroup, teamId));

        dispatch(
          ActionCreators.selectTeamRanking(onlyLetterOfGroup, rank, teamId)
        );
      }
      if (currentTeam?.selectedRanking === rank) {
        dispatch(ActionCreators.deselectTeamRanking(onlyLetterOfGroup, teamId));
      }
    }
  };

  return (
    <div className="team-table-row p-2   align-items-center">
      <form className="d-flex align-items-center">
        <img className="small-flag" src={flagImage} alt="flag" />
        <span className="name-team">{teamName}</span>
        <div className="ml-auto d-flex ranking-box ">
          <>
            {/* user did not add predictions yet */}
            <div
              style={currentTeam?.selectedRanking === 1 ? displayedBtn : null}
              className="ranking-btn ranking-btn-1 px-2 py-1"
              onClick={() => handleClick(1, dataSources?.user)}
            >
              1
            </div>
            <div
              style={currentTeam?.selectedRanking === 2 ? displayedBtn : null}
              className="ranking-btn ranking-btn-2 px-2 py-1"
              onClick={() => handleClick(2, dataSources?.user)}
            >
              2
            </div>
            <div
              style={currentTeam?.selectedRanking === 3 ? displayedBtn : null}
              className="ranking-btn ranking-btn-3 px-2 py-1"
              onClick={() => handleClick(3, dataSources?.user)}
            >
              3
            </div>
            <div
              style={currentTeam?.selectedRanking === 4 ? displayedBtn : null}
              className="ranking-btn ranking-btn-4 px-2 py-1"
              onClick={() => handleClick(4, dataSources?.user)}
            >
              4
            </div>
          </>
        </div>
        {viOrX && <img
          style={{ position: "absolute", marginLeft: "71vw" }}
          src={viOrX}
          alt="success or failure"
        />}
      </form>
    </div>
  );
}

export default GroupStageSingleTeamBox;
