import React, { useCallback, useContext, useEffect, useState } from "react";
import viLarge from "./images/viLarge.png";
import viLargeActive from "./images/viLargeActive.png";
import GroupStageSingleTeamBox from "./GroupStageSingleTeamBox";
import { TournamentContext } from "./contexts/TournamentContext";

const containerStyle = {
  position: "relative",
  transform: "translateY(calc(-100% - 1rem))",
};

function TeamsBox({ groupLetter, teamsInGroup, groupId, userPredictionGroup }) {
  const { state } = useContext(TournamentContext);
  const serverGroupRanking = userPredictionGroup?.GroupPredictionRankings;

  const teams = state?.tournament?.teams;
  const teamsGroupe = [];
  let singleTeam;
  for (const { id: teamId } of teamsInGroup) {
    singleTeam = teams.find(team => team?.id === teamId);
    teamsGroupe.push(singleTeam);
  }

  const rankedTeamsArr = teamsInGroup.filter((t) => t.selectedRanking);

  return (
    <div style={{ height: 0 }}>
      <div
        className="container mt-3 group-container d-flex"
        style={containerStyle}
      >
        <div className="teams-box">
          <div className="team-box-header p-2 d-flex border-bottom">
            <img
              className={
                rankedTeamsArr.length === 4 ? "large-vi-active" : "large-vi"
              }
              src={rankedTeamsArr.length === 4 ? viLargeActive : viLarge}
              alt="vi"
            />
            <p className="mb-0 team-box__group-name">{groupLetter}</p>
            {/* <p className="team-box__details mb-0">Details</p> */}
          </div>
          {teamsGroupe?.sort((t1, t2) => (+t1?.TournamentTeamRanking?.displayRanking - +t2?.TournamentTeamRanking?.displayRanking) || 1)?.map((team) => {
            return (
              <GroupStageSingleTeamBox
                key={team?.id}
                teamId={team?.id}
                teamName={team?.name}
                flagImage={team?.shirt_img}
                selectedRanking={team?.selectedRanking}
                ranking={team?.ranking}
                groupLetter={groupLetter}
                groupId={groupId}
                userAddedTeamId={serverGroupRanking?.find(
                  (UserAddedTeam) => UserAddedTeam?.teamId === team?.id
                )}
              />
            );
          })}
        </div>
        <div className="next-stage"></div>
      </div>
    </div>
  );
}

export default TeamsBox;
