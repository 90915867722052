import React, { useContext } from "react";
import { TournamentContext } from "./contexts/TournamentContext";
import GroupStageContainer from "./GroupStageContainer";
import { Spinner } from "react-bootstrap";
import Final from "./knockOut/Final";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import StageSizes from "../constants/stage-sizes";
import roundsData from '../constants/rounds-data';
import KnockoutRound from "./knockOut/knockout-round";

const TournamentBody = () => {
  const {
    state: { tournament },
    loading,
    setStageSize,
    setStageIndex
  } = useContext(TournamentContext);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    onSwipe: () => {
      document.activeElement.blur();
    },
    beforeChange: (oldIndex, newIndex) => {
      setStageSize(StageSizes[newIndex]);

      setStageIndex(newIndex + 1);
    },
  };

  if (!loading) {
    return (
      <div
        style={{
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          height: "100vw",
        }}
      >
        <Spinner animation="border" style={{ color: "rgba(121, 192, 0, 1)" }} />
      </div>
    );
  } else {
    return (
      <div>
        <div className="TournamentBody" id="scrollableTournamentBody">
          <div className="sliderContainer">
            <Slider {...sliderSettings}>
              <div style={{ height: "100%" }} index={1}>
                <GroupStageContainer />
              </div>
              {
                false && tournament?.rounds?.length &&
                <>
                  {roundsData.map((roundData, i) => {
                    console.log(tournament?.rounds);
                    return <div>
                      <KnockoutRound roundData={roundData} round={tournament?.rounds.find(r => r.name === roundData.name)}/>
                    </div>
                  })}
                  <div style={{ height: "100%" }} index={roundsData.length + 2}>
                    <Final />
                  </div>
                </>
              }
            </Slider>
          </div>
        </div>
      </div>
    );
  }
};

export default TournamentBody;
