import "./App.css";
import "./btn.css";
import React from "react";
import { Switch, Route } from "react-router-dom";
import TournamentContainer from "./Tournament/TournamentContainer";
import SubmissionSuccess from "./Tournament/SubmissionSuccess";
import UnAuthorizedUser from "./Tournament/UnAuthorizedUser";

const App = () => {
  return (
    <div id="bootstrap-overrides" className="App">
      <Switch>
        <Route exact path={"/submission-success"}>
          <SubmissionSuccess />
        </Route>
        <Route exact path={"/unauthorized"}>
          <UnAuthorizedUser />
        </Route>
        <Route exact path={"/"}>
          <TournamentContainer />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
