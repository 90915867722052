import React from "react";
import "./tournament.css";
import TournamentBody from "./TournamentBody";
import { TournamentProvider } from "./contexts/TournamentContext";

function TournamentContainer() {
  return (
    <TournamentProvider>
      <div className="TournamentContainer">
        <TournamentBody />
      </div>
    </TournamentProvider>
  );
}

export default TournamentContainer;
