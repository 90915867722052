import { Modal, Fade, makeStyles, Backdrop } from "@material-ui/core";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container_btn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "20px",
  },
  btn: {
    "&:active:not(:disabled)": {
      backgroundColor: "#7ebc15",
      color: "white",
    },
    "&:disabled": {
      color: "#ebebeb",
      borderColor: "#ebebeb",
    },
    width: "80px",
    height: "30px",
    border: "1px solid #7ebc15",
    borderRadius: "20px",
    color: "#7ebc15",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
  },
}));
const ModalSubmit = ({ handleOk, handleClose, opened }) => {
  const [disabled, setDisabled] = useState(false);

  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={opened}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={opened}>
        <div className={classes.paper}>
          <p className="text-center" id="transition-modal-description">
            ¿Estas seguro de que quieres guardar estos cambios?
          </p>
          <div className={classes.container_btn}>
            <button
              disabled={disabled}
              onClick={() => {
                handleClose();
              }}
              className={classes.btn}
            >
              <span>No</span>
            </button>
            <button
              disabled={disabled}
              onClick={() => {
                setDisabled(true);
                handleOk().then(() =>
                  setTimeout(() => {
                    setDisabled(false);
                  }, 550)
                );
              }}
              className={classes.btn}
            >
              <span>Si</span>
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalSubmit;
