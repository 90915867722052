import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import { BASE_URL } from "../../environment";
import { useHistory } from "react-router-dom";
import tournamentReducer from "./../../state/tournament-reducer";
import { ActionCreators } from "../../state/action-creators";
import localForage from "localforage";
import { setState } from "../../state/action-creators/action-creators";
import moment from "moment";
import TournamentPhase from './../../enums/tournamentPhase';
import StageSizes from '../../constants/stage-sizes';
import ModalSubmit from '../knockOut/TournamentSubmitModal';

export const TournamentContext = createContext();

export function TournamentProvider({ children }) {
  const initialState = {
    tournament: {
      teams: [],
      groups: [],
      rounds: [],
      startTime: null,
      tournamentPhase: null
    }
  };

  const [state, dispatch] = React.useReducer(tournamentReducer, initialState);
  const [loading, setLoading] = useState(true);
  const [stageSize, setStageSize] = useState(StageSizes[0]);
  const [stageIndex, setStageIndex] = useState(0);
  const [postOrPut, setPostOrPut] = useState("post");
  const [enableEditing, setEnableEditing] = useState(true);
  const [editModalOpened, setEditModelOpened] = useState(false);

  const toggleEdit = useCallback(() => {
    setEnableEditing(curr => !curr);
  }, []);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const savedToken = sessionStorage.getItem("token");
        const token =
          savedToken?.length && +savedToken !== 0 && savedToken !== "null"
            ? savedToken
            : null;
        const savedState = await localForage.getItem("state");

        let tournament, submission;
        {
          const res = await axios
            .get(`${BASE_URL}/api/get-tournament?tournamentId=281`, {
              headers: {
                "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
                "jwt-token": token,
              },
            })
          if (!res?.data) return;
          tournament = res?.data?.tournament;
          // if (didTournamentStart && !token) {
          //   history.push("/unauthorized");
          // }
        }
        if (token) {
          const res = await axios.get(
            `${BASE_URL}/api/get-tournament-submission?tournamentId=281`,
            {
              headers: {
                "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
                "jwt-token": token,
              },
            }
          );
          submission = res?.data?.submission;
        }
        if (submission) {
          setPostOrPut("put");
          setEnableEditing(false);
          dispatch(
            ActionCreators.insertTournament({ tournament, submission })
          );
        } else {
          let tournamentPhase;

          if (moment(tournament.startTime).isAfter(moment())) {
            tournamentPhase = TournamentPhase.beforeStart
          } else if (moment(tournament.groupStageEndTime).isAfter(moment())) {
            tournamentPhase = TournamentPhase.groupStage
          } else if (moment(tournament.knockoutStageStartTime).isAfter(moment())) {
            tournamentPhase = TournamentPhase.beforeKnockout
          } else if (!tournament.isFinished) {
            tournamentPhase = TournamentPhase.knockoutStage
          } else {
            tournamentPhase = TournamentPhase.tournamentEnded
          }
          switch (tournamentPhase) {
            case TournamentPhase.groupStage:
            case TournamentPhase.knockoutStage:
            case TournamentPhase.beforeKnockout:
            case TournamentPhase.tournamentEnded:
              history.push("/unauthorized");
              return;
            case TournamentPhase.beforeStart:
              if (savedState && !savedState.knockoutPredictionsOnly) {
                dispatch(setState(savedState));
              } else {
                dispatch(ActionCreators.insertTournament({ tournament: { ...tournament, tournamentPhase } }));
              }
              break;
            default:
              break;
          }
        }
        setLoading(true);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleSubmit = () => {
    const savedToken = sessionStorage.getItem("token");
    const token =
      savedToken?.length && +savedToken !== 0 && savedToken !== "null"
        ? savedToken
        : null;

    if (token) {
      if (postOrPut === "put") {
        setEditModelOpened(true);
        return;
      }
      const tournamentPrediction = {
        tournamentId: state.tournament.id,
        groupPredictions: Object.fromEntries(
          state.tournament.groups.map((g) => [
            g.name,
            Object.fromEntries(
              g.teams
                .map((t) => {
                  return [t?.selectedRanking?.toString(), t.id];
                })
                .filter((e) => !!e[0])
            ),
          ])
        ),
        matchPredictions: [
          ...state.tournament.rounds
            .map((r) => r.matches)
            .flat()
            .map((m) => ({
              matchId: m.id,
              result: m.selectedResult,
            })),
        ],
        onlyGroups: true,
        onlyKnockout: false
      };

      return axios
        .post(
          `${BASE_URL}/api/add-tournament-predictions`,
          tournamentPrediction,

          {
            headers: {
              "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
              "jwt-token": token,
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            setPostOrPut("put");
            console.log("submission sent!");
            history.push("/submission-success");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      localForage.setItem("state", state).then(() => {
        window.parent.location.href =
          "http://q.mx.bet.cdrsbg.com/deportescolombiaCelia/#/HomePage?openlogin=true&afterloginurl=CopaAmericaPage";
      });
    }
  };

  const handleEditSubmit = () => {
    const savedToken = sessionStorage.getItem("token");
    const token =
      savedToken?.length && +savedToken !== 0 && savedToken !== "null"
        ? savedToken
        : null;

    if (token) {
      const tournamentPrediction = {
        tournamentId: state.tournament.id,
        groupPredictions: Object.fromEntries(
          state.tournament.groups.map((g) => [
            g.name,
            Object.fromEntries(
              g.teams
                .map((t) => {
                  return [t?.selectedRanking?.toString(), t.id];
                })
                .filter((e) => !!e[0])
            ),
          ])
        ),
        matchPredictions: [
          ...state.tournament.rounds
            .map((r) => r.matches)
            .flat()
            .map((m) => ({
              matchId: m.id,
              result: m.selectedResult,
            })),
        ],
        onlyGroups: true,
        onlyKnockout: false
      };
      return axios
        .put(
          `${BASE_URL}/api/edit-tournament-predictions`,
          tournamentPrediction,

          {
            headers: {
              "app-api-key": "e684d2c4-eddd-44e4-b1f7-2ab9e75122ed",
              "jwt-token": token,
            },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            setPostOrPut("put");

            console.log("submission was edited successfully!");
            history.push("/submission-success");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const formFilled =
    state.tournament.groups?.length &&
    state.tournament.groups.every((g) => {
      return g.teams.filter((t) => !!t.selectedRanking)?.length === 4;
    }) &&
    state.tournament.rounds?.length &&
    state.tournament.rounds?.every((r) =>
      r.matches.every((m) => !!m.selectedResult)
    );

  const value = {
    state,
    dispatch,
    stageSize,
    setStageSize,
    stageIndex,
    setStageIndex,
    handleSubmit,
    postOrPut,
    enableEditing,
    toggleEdit,
    loading,
    formFilled,
    setEnableEditing,
    editModalOpened,
    setEditModelOpened
  };

  return (
    <TournamentContext.Provider value={value}>
      <>
        {children}
      </>
      <ModalSubmit handleOk={handleEditSubmit} handleClose={() => setEditModelOpened(false)} opened={editModalOpened} />
    </TournamentContext.Provider>
  );
}


