import React, { useContext, useState } from "react";
import { TournamentContext } from "./contexts/TournamentContext";
import TeamsBox from "./TeamsBox";
import TournamentHeadline from "./headers/TournamentHeadline";

function GroupStageContainer() {
  const { state, stageSize, userAddedPredictions, handleSubmit, enableEditing, formFilled } =
    useContext(TournamentContext);
  const serverGroupPredictions = userAddedPredictions?.groupPredictions;
  const groups = state?.tournament?.groups;
  const submitDisabled =
    !enableEditing ||
    !state?.tournament?.groups?.every(g => g?.teams?.length && g?.teams?.every(t => t?.selectedRanking));

  return (
    <div className="groups-flex-container" style={{ height: stageSize }}>
      <div style={{ marginBottom: "5rem" }}>
        <TournamentHeadline
          headline={"FASE DE GRUPOS"}
          paragraph={`¡Adivina ya! ¿qué equipos ocuparán el 1er, 2do, 3er y
         4to lugar al finalizar la Fase de Grupos?
         ¡Piensa, piensa…! Porque 1.000 MILLONES DE PESOS
         pueden ser tuyos… ¡QUÉ LOCURA!`}
          editButton={true}
          completed={state.tournament.groups.every(
            (g) => g.teams.filter((t) => t.selectedRanking)?.length === 4
          )}
          fromGroups
        />
      </div>

      {groups?.map((group) => {
        return (
          <TeamsBox
            key={group?.id}
            groupId={group?.id}
            groupLetter={group?.displayName}
            teamsInGroup={group?.teams}
            userPredictionGroup={serverGroupPredictions?.find(
              (userAddedGroup) =>
                userAddedGroup?.TournamentGroupId === group?.id
            )}
            GroupStageContainer
          />
        );
      })}
      <div style={{ height: 0 }}>
        <button
          style={{ position: 'relative', transform: 'translateY(calc(-17rem))' }}
          onClick={() => handleSubmit()}
          className={`button ${submitDisabled ? "button_disabled" : ""}`}
          disabled={submitDisabled}
        >
          Enviar
        </button>
      </div>
    </div>
  );
}

export default GroupStageContainer;
