import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import tooltip from "./images/Question-Mark.png";

export default function TriggersTooltips({ fromGroups }) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const longText = (
    <p>
      ¿Estás listo para ganar MIL MILLONES DE PESOS? Ponte cómodo y ve por ello.
      Es muy fácil.
      <br />
      <br /> 1. Elige de cada grupo las selecciones que pasarán a la siguiente
      fase y en qué puesto finalizan la fase de grupos.
      <br />
      <br />
      2. Desliza la pantalla a la izquierda y selecciona qué selecciones pasan
      de ronda hasta llegar al campeón de la Copa.
      <br />
      <br /> 3. Responde las tres preguntas finales <br />
      <br />
      Podrás editar tus pronósticos tantas veces como quieras hasta que comience
      el campeonato. Prepárate y… ¡gana LOS MIL MILLONES DE PESOS!
    </p>
  );

  return (
    <div style={!fromGroups ? { display: "none" } : null}>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={longText}
          >
            <Button onClose={handleTooltipClose} onClick={handleTooltipOpen}>
              <div className="d-flex">
                <span style={{ fontWeight: "bolder" }}>
                  ¿cómo jugar?{" "}
                  <img
                    src={tooltip}
                    style={{ display: "inline", width: 20 }}
                    alt="tooltip"
                  />
                </span>
              </div>
            </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </div>
  );
}
