import React, { useContext, useEffect, useState } from "react";
import checkedVi from "../images/smallVi.png";
import checkedViDisabled from "../images/smallViDisabled.png";
import unCheckedVi from "../images/emptyVi.png";
import successViKnockOut from "../images/successViKnockOut.png";
import failureXKnockOut from "../images/failureViKnockOut.png";
import questionMark from "../images/questionMark.svg";
import bracketsEmpty from "../images/bracketsEmpty.svg";
import bracketsHalf from "../images/bracketsHalf.svg";
import bracketsFull from "../images/bracketsFull.svg";
import detailsImg from "../images/Details.svg";
import KnockOutOptionSelect from "./KnockOutOptionSelect";
import isNullOrUndefined from "./../../helpers/is-null-or-undefined";
import { TournamentContext } from "../contexts/TournamentContext";
import { ActionCreators } from "../../state/action-creators";
import TeamStyling from "./knockOutStyling/TeamStyling";

const backgroundCheckedHome = {
  background: "linear-gradient(91.47deg, #508CE3 0.5%, #4D678D 100%)",
  borderRadius: "20px 20px 0 0",
  height: "100%",
};
const backgroundCheckedAway = {
  ...backgroundCheckedHome,
  borderRadius: "0px 0px 20px 20px",
};

const backgroundCheckedHome_gray = {
  ...backgroundCheckedHome,
  background: "#CFCFCF",
};

const backgroundCheckedAway_gray = {
  ...backgroundCheckedAway,
  background: "#CFCFCF",
};

const noBtnStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "left",
  paddingLeft: "1rem",
  position: "relative",
  transform: "translateY(calc(-100% - 1rem))",
};

const btnStyle = {
  ...noBtnStyle,
  transform: "translateY(calc(-76% - 1rem))",
};

function KnockOutMatchBox({
  matchId,
  selectedHomeTeamId,
  selectedAwayTeamId,
  currentMatch,
  currentRound,
}) {
  const { enableEditing, postOrPut, state, dispatch } =
    useContext(TournamentContext);

  //get teams
  const tournamentTeams = state?.tournament?.teams;
  const currentHomeTeam = tournamentTeams?.find(
    (team) => team?.id === selectedHomeTeamId
  );
  const currentAwayTeam = tournamentTeams?.find(
    (team) => team?.id === selectedAwayTeamId
  );

  let homeBackgroundState;
  let awayBackgroundState;
  let checkedViState;

  if (postOrPut === "put" && !enableEditing) {
    homeBackgroundState = backgroundCheckedHome_gray;
    awayBackgroundState = backgroundCheckedAway_gray;
    checkedViState = checkedViDisabled;
  }

  if (postOrPut === "put" && enableEditing) {
    homeBackgroundState = backgroundCheckedHome;
    awayBackgroundState = backgroundCheckedAway;

    checkedViState = checkedVi;
  }
  if (postOrPut === "post") {
    homeBackgroundState = backgroundCheckedHome;
    awayBackgroundState = backgroundCheckedAway;

    checkedViState = checkedVi;
  }

  const level = 0;

  const selectedResult = currentMatch?.selectedResult;
  const matchScore = currentMatch?.matchScore;
  let successOrFailure;

  let whoWon;
  if (matchScore) {
    if (matchScore?.home_score > matchScore?.away_score) {
      whoWon = "home_win";
    }
    if (matchScore?.home_score < matchScore?.away_score) {
      whoWon = "away_win";
    }
  }

  if (whoWon) {
    if (whoWon === selectedResult
      && currentMatch?.awayTeamId && selectedAwayTeamId === currentMatch?.awayTeamId
      && currentMatch?.homeTeamId && selectedHomeTeamId === currentMatch?.homeTeamId) {
      successOrFailure = successViKnockOut;
    } else {
      successOrFailure = failureXKnockOut;
    }
  }

  // if(matchScore === )

  console.log(successOrFailure);

  const handleClick = (whoWon) => {
    if (!enableEditing) return;
    if (whoWon !== selectedResult) {
      dispatch(ActionCreators.selectWinnerForMatch(matchId, whoWon));
    } else {
      dispatch(ActionCreators.deselectWinnerForMatch(matchId, whoWon));
    }
  };

  let brackets;
  if (!selectedHomeTeamId && !selectedAwayTeamId) {
    brackets = bracketsEmpty;
  }

  if (selectedHomeTeamId && !selectedAwayTeamId) {
    brackets = bracketsHalf;
  }

  if (selectedAwayTeamId && !selectedHomeTeamId) {
    brackets = bracketsHalf;
  }

  if (selectedHomeTeamId && selectedAwayTeamId) {
    brackets = bracketsFull;
  }

  const bracketsImgElement = (
    <img
      src={brackets}
      alt="bracketsEmpty"
      style={{
        transform: level === 1 && currentAwayTeam ? "rotateX(180deg)" : null,
      }}
    />
  );

  let homeBackgroundField;
  let TeamFontColor;

  if (selectedResult === "home_win") {
    homeBackgroundField = {
      ...homeBackgroundState,
      flex: 1,
      paddingLeft: "1rem",
      paddingRight: "1rem",
    };
    TeamFontColor = { color: "white" };
  } else {
    homeBackgroundField = {
      flex: 1,
      paddingLeft: "1rem",
      paddingRight: "1rem",
    };
  }

  let awayBackgroundField;
  if (selectedResult === "away_win") {
    awayBackgroundField = {
      ...awayBackgroundState,
      flex: 1,
      paddingLeft: "1rem",
      paddingRight: "1rem",
    };
    TeamFontColor = { color: "white" };
  } else {
    awayBackgroundField = {
      flex: 1,
      paddingLeft: "1rem",
      paddingRight: "1rem",
    };
  }

  return (
    <div style={{ height: 0 }}>
      <div style={btnStyle}>
        <div className="d-flex">
          {bracketsImgElement}
          <div className="knock-out-match-box">
            {/* color selected home team */}
            {currentHomeTeam && !isNullOrUndefined(currentHomeTeam) ? (
              <TeamStyling
                home
                checkedViState={checkedViState}
                currentHomeTeam={currentHomeTeam}
                selectedResult={selectedResult}
                handleClick={handleClick}
                homeBackgroundField={homeBackgroundField}
                TeamFontColor={TeamFontColor}
                selectedVi={checkedViState}
              />
            ) : (
              <div
                style={{ borderRadius: "20px 20px 0 0", flex: 1 }}
                className="row-empty"
              >
                <img
                  width="25px"
                  height="25px"
                  style={{ margin: 10 }}
                  src={questionMark}
                  alt="vi"
                />
              </div>
            )}

            {/* away team styling */}
            {currentAwayTeam && !isNullOrUndefined(currentAwayTeam) ? (
              <TeamStyling
                away
                checkedViState={checkedViState}
                currentAwayTeam={currentAwayTeam}
                selectedResult={selectedResult}
                handleClick={handleClick}
                awayBackgroundField={awayBackgroundField}
                TeamFontColor={TeamFontColor}
                selectedVi={checkedViState}
              />
            ) : (
              <div
                style={{ borderRadius: "0 0 20px 20px", flex: 1 }}
                className="row-empty"
              >
                <img
                  width="25px"
                  height="25px"
                  style={{ margin: 10 }}
                  src={questionMark}
                  alt="vi"
                />
              </div>
            )}
          </div>
        </div>
        {successOrFailure && <img
          style={{ position: "absolute", marginLeft: "69vw" }}
          src={successOrFailure}
          alt="success or failure"
        />}
      </div>
    </div>
  );
}

export default KnockOutMatchBox;
