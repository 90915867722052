import React, { useRef, useState } from "react";
import successSubmitVi from "./images/successSubmitVi.png";
import checkedVi from "./images/smallVi.png";
import { Link } from "react-router-dom";
const headerColor = {
  background: "linear-gradient(154.49deg, #ADFF22 7.45%, #7EBC15 90.73%)",
  margin: "auto",
};

const SubmissionSuccess = ({}) => {
  // const eligibilityRef = useRef(sessionStorage.getItem("eligibility"));

  // const eligibility = eligibilityRef.current;
  // const notEligibleText = `¡Lo sentimos! No puedes optar al premio de esta promoción ya que tu cuenta no cumple con los requisitos legales obligatorios. Pero sí puedes un buen rato de diversión completando tus pronósticos de la Eurocopa y después apostar en cualquiera de los mercados disponibles. `;
  // const eligibleText = `¡Pronósticos listos! Seguro que lo petarás al máximo con tu porra de la Euro.`;

  return (
    <>
      <div style={headerColor} className="pb-3">
        <div className="text-center pt-3">
          <img src={successSubmitVi} alt="" />
        </div>
        <h1 className="text-center" style={{ color: "#FFFFFF", fontSize: 22 }}>
          ¡Porra Completada!
        </h1>
      </div>
      <div className="container mt-4">
        <h2 style={{ fontSize: 18 }}>
          Enhorabuena, ya tenemos tus picks para la Copa America!
        </h2>
        {/* <div style={{ fontWeight: 300 }}>
          {+eligibility === 0
            && <p>
              {notEligibleText}
            </p>
          }
          {+eligibility === 1
            && <p>
              {eligibleText}
            </p>
          }
        </div> */}
      </div>

      <div className="mt-5">
        <button className="claim-bet">
          <Link style={{ color: "white", textDecoration: "none" }} to="/">
            <p className="claim-bet-text">Finalizar</p>
          </Link>
        </button>
      </div>
    </>
  );
};
export default SubmissionSuccess;
