import React, { useContext } from "react";
import viLarge from "../images/viLarge.png";
import viLargeActive from "../images/viLargeActive.png";
import edit from "../images/edit.png";
import { TournamentContext } from "../contexts/TournamentContext";
import HowToPlay from "../HowToPlay";
import moment from "moment";
function TournamentHeadline({
  headline,
  paragraph,
  editButton = false,
  completed,
  fromGroups,
}) {
  const { postOrPut, enableEditing, handleEdit, toggleEdit, state } =
    useContext(TournamentContext);

  const hasTournamentStarted = moment(state.tournament.startTime).isBefore(moment());

  return (
    <div className="EuroHeadline container">
      <div className="mt-3 d-flex">
        <HowToPlay fromGroups={fromGroups} />
      </div>
      
      <div className="d-flex EuroHeadline__headline-and-paragraph align-items-center mb-2">
        <img
          className={
            "large-vi mt-3 mr-3" + (completed ? " large-vi-active" : "")
          }
          src={completed ? viLargeActive : viLarge}
        ></img>
        <h1 className="EuroHeadline__header-headline">{headline}</h1>
        {editButton && postOrPut === "put" ? (
          <button
            disabled={hasTournamentStarted}
            onClick={toggleEdit}
            className={
              !enableEditing && !hasTournamentStarted
                ? "ml-3 btn-edit-enabled d-flex py-2 px-3 mt-3"
                : "ml-3 btn-edit-disabled d-flex py-2 px-3 mt-3"
            }
          >
            <img className="mr-2" src={edit}></img>Editar
          </button>
        ) : null}
      </div>

      <p className="EuroHeadline__paragraph-headline">{paragraph}</p>
    </div>
  );
}

export default TournamentHeadline;
