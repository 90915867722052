import produce from "immer";
import ActionTypes from "./action-types";
import TournamentPhase from './../enums/tournamentPhase';

function updateNextMatches(tournament, matchWithUpdatedSelectedResult) {
  const nextHomeTeamMatchToUpdate = tournament.rounds
    .map((r) => r.matches)
    .flat()
    .find(
      (m) =>
        m.homeTeamFrom?.previousStage === "playoff" &&
        +m.homeTeamFrom.previousPosition?.matchId ===
        matchWithUpdatedSelectedResult.id
    );
  if (nextHomeTeamMatchToUpdate) {
    switch (matchWithUpdatedSelectedResult.selectedResult) {
      case "home_win":
        nextHomeTeamMatchToUpdate.selectedHomeTeamId =
          matchWithUpdatedSelectedResult.selectedHomeTeamId;
        updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
        break;
      case "away_win":
        nextHomeTeamMatchToUpdate.selectedHomeTeamId =
          matchWithUpdatedSelectedResult.selectedAwayTeamId;
        updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
        break;
      case null:
        if (nextHomeTeamMatchToUpdate.selectedResult === "home_win")
          nextHomeTeamMatchToUpdate.selectedResult = null;
        nextHomeTeamMatchToUpdate.selectedHomeTeamId = null;
        updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
        break;
      default:
        break;
    }
  }
  const nextAwayTeamMatchToUpdate = tournament.rounds
    .map((r) => r.matches)
    .flat()
    .find(
      (m) =>
        m.awayTeamFrom?.previousStage === "playoff" &&
        +m.awayTeamFrom.previousPosition.matchId ===
        matchWithUpdatedSelectedResult.id
    );
  if (nextAwayTeamMatchToUpdate) {
    switch (matchWithUpdatedSelectedResult.selectedResult) {
      case "home_win":
        nextAwayTeamMatchToUpdate.selectedAwayTeamId =
          matchWithUpdatedSelectedResult.selectedHomeTeamId;
        updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
        break;
      case "away_win":
        nextAwayTeamMatchToUpdate.selectedAwayTeamId =
          matchWithUpdatedSelectedResult.selectedAwayTeamId;
        updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
        break;
      case null:
        if (nextAwayTeamMatchToUpdate.selectedResult === "away_win")
          nextAwayTeamMatchToUpdate.selectedResult = null;
        nextAwayTeamMatchToUpdate.selectedAwayTeamId = null;
        updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
        break;
      default:
        break;
    }
  }
}

function updateMatchesByGroupRankings(
  tournament,
  groupKey,
  ranking,
  teamWithNewSelectedRanking /* could be null */
) {
  const nextHomeTeamMatchToUpdate = tournament.rounds
    .map((r) => r.matches)
    .flat()
    .find(
      (m) =>
        m.homeTeamFrom?.previousStage === "ranking" &&
        m.homeTeamFrom.previousPosition[0].group === groupKey && //TODO: later add functionality for multiple team selection
        +m.homeTeamFrom.previousPosition[0].ranking === ranking
    );
  if (nextHomeTeamMatchToUpdate) {
    nextHomeTeamMatchToUpdate.selectedHomeTeamId =
      teamWithNewSelectedRanking?.id || null;
    updateNextMatches(tournament, nextHomeTeamMatchToUpdate);
  }
  const nextAwayTeamMatchToUpdate = tournament.rounds
    .map((r) => r.matches)
    .flat()
    .find(
      (m) =>
        m.awayTeamFrom?.previousStage === "ranking" &&
        m.awayTeamFrom.previousPosition[0].group === groupKey &&
        +m.awayTeamFrom.previousPosition[0].ranking === ranking
    );
  if (nextAwayTeamMatchToUpdate) {
    nextAwayTeamMatchToUpdate.selectedAwayTeamId =
      teamWithNewSelectedRanking?.id || null;
    updateNextMatches(tournament, nextAwayTeamMatchToUpdate);
  }
}

export default function tournamentReducer(state, action) {
  return produce(state, (draft) => {
    try {
      switch (action.type) {
        case ActionTypes.INSERT_TOURNAMENT:
          {
            const { tournament, submission } = action.payload;

            draft.tournament.tournamentPhase = tournament.tournamentPhase
            draft.tournament.startTime = tournament.startTime
            draft.tournament.id = tournament.id;
            draft.tournament.teams = [
              ...tournament.TournamentGroups.map((tg) => tg.teams).flat(),
            ];
            draft.tournament.rounds = [
              ...tournament.rounds.map((r) => ({
                ...r,
                matches: r.matches.map((m) => ({
                  id: m.id,
                  status_code: m.status_code,
                  match_start: m.match_start,
                  stage_name: m.stage_name,
                  venue: m.venue,
                  homeTeamFrom: m.homeTeamFrom,
                  awayTeamFrom: m.awayTeamFrom,
                  roundId: m.roundId,
                  matchScore: m.matchScore,

                  // this will be updated during the tournament according to result
                  awayTeamId: m.awayTeamId,
                  homeTeamId: m.homeTeamId,
                  
                  selectedAwayTeamId: null,
                  selectedHomeTeamId: null,
                  selectedResult:
                    submission?.tournamentMatchPredictions?.find(
                      (tmp) => tmp.matchId === m.id
                    )?.result || null, //"away_win", "home_win" or null if no team selected
                })),
              })),
            ];
            draft.tournament.groups = [
              ...tournament.TournamentGroups.map((tg) => ({
                ...tg,
                teams: tg.teams.map((t) => ({
                  id: t.id,
                  selectedRanking:
                    submission?.groupPredictions
                      ?.find((gp) => gp.TournamentGroupId === tg.id)
                      ?.GroupPredictionRankings?.find(
                        (gpr) => gpr.teamId === t.id
                      )?.ranking || null,
                  ranking: t.TournamentTeamRanking.ranking, //this is the actual ranking in the tournament
                })),
              })),
            ];
            for (const group of draft.tournament.groups) {
              for (const team of group.teams) {
                updateMatchesByGroupRankings(
                  draft.tournament,
                  group.key,
                  team.selectedRanking,
                  team
                );
              }
            }
          }
          break;
        case ActionTypes.SELECT_WINNER_FOR_MATCH:
          {
            const { result, matchId } = action.payload;
            if (!["home_win", "away_win"].find((t) => t === result)) {
              throw new Error("result can be 'home_win' or 'away_win'");
            }
            let matchToUpdate = draft.tournament.rounds
              .map((r) => r.matches)
              .flat()
              .find((mp) => mp.id === matchId);
            if (matchToUpdate) {
              matchToUpdate.selectedResult = result;
              updateNextMatches(draft.tournament, matchToUpdate);
            }
          }
          break;
        case ActionTypes.DESELECT_WINNER_FOR_MATCH:
          {
            const { matchId } = action.payload;
            let matchToUpdate = draft.tournament.rounds
              .map((r) => r.matches)
              .flat()
              .find((mp) => mp.id === matchId);
            if (matchToUpdate) {
              matchToUpdate.selectedResult = null;
              updateNextMatches(draft.tournament, matchToUpdate);
            }
          }
          break;
        case ActionTypes.SELECT_TEAM_RANKINKG:
          {
            const { groupKey, ranking, teamId } = action.payload;
            const groupToUpdate = draft.tournament.groups.find(
              (g) => g.key === groupKey
            );
            if (
              !groupToUpdate ||
              groupToUpdate.teams.find((t) => t?.selectedRanking === ranking)
            )
              break;
            const teamToUpdate = groupToUpdate.teams.find(
              (t) => t.id === teamId
            );
            if (teamToUpdate) {
              teamToUpdate.selectedRanking = ranking;
              updateMatchesByGroupRankings(
                draft.tournament,
                groupKey,
                ranking,
                teamToUpdate
              );
            }
          }
          break;
        case ActionTypes.DESELECT_TEAM_RANKING:
          {
            const { groupKey, teamId } = action.payload;
            const groupToUpdate = draft.tournament.groups.find(
              (g) => g.key === groupKey
            );
            if (!groupToUpdate) break;
            const teamToUpdate = groupToUpdate.teams.find(
              (t) => t.id === teamId
            );
            if (teamToUpdate) {
              const oldRanking = teamToUpdate.selectedRanking;
              teamToUpdate.selectedRanking = null;
              updateMatchesByGroupRankings(
                draft.tournament,
                groupKey,
                oldRanking,
                null
              );
            }
          }
          break;
        case ActionTypes.SET_PREDICTED_CORNERS: {
          draft.tournament.predictedCorners =
            action.payload.predictedCornersNumber;
          break;
        }
        case ActionTypes.SET_STATE:
          action?.payload?.state &&
            (draft.tournament = { ...action.payload.state.tournament });
          break;
        default:
          break;
      }
    } catch (error) {
      if (process.env.NODE_ENV === "development") throw error;
      console.error("error in reducer", error);
    }
  });
}
